#main8 {
  position: relative;
  background: #5271ff;
  width: 100%;
  /* bottom: -300px; */
  /* z-index: -1; */
}
.contactUsMain {
  position: relative;
  top: -100px;
  background: #fff;
  padding: 70px 50px;
  margin: 50px 50px 0px 50px;
  /* margin-bottom: -200px; */
  text-transform: capitalize;
  box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.055),
    0 -0rem 2rem rgba(0, 0, 0, 0.055);
}
.contactUsTopHeadingDiv p {
  color: #03178c;
  font-size: 3rem;
}

.contactUsChildMain {
  display: flex;
  /* flex-direction: row-reverse; */
}

.contactUsBtnSubmit {
  margin-top: 20px !important;
}

.infoTextDivChild {
  margin-right: 40px !important;
}
#standard-multiline-flexible {
  height: 80px !important;
}

.contactUsMoreInfoDiv {
  padding-right: 30px;
}
.contactUsCompanyInfoDiv {
  text-transform: capitalize;
}

.contactUsCompanyInfoChild h3 {
  color: #03178c;
}

@media (max-width: 768px) {
  .contactUsCompanyInfoMain {
    margin-top: 50px !important ;
    text-align: justify;
  }
}

@media (max-width: 425px) {
  .contactUsMain {
    margin: 20px 20px 0px 20px;
    padding: 10px 30px;
    /* margin-bottom: -200px; */
  }
  .contactUsTopHeadingDiv p {
    font-size: 2rem;
  }
}

@media (max-width: 320px) {
  .contactUsMain {
    /* margin-bottom: -200px; */
  }
  .contactUsTopHeadingDiv p {
    font-size: 1.5rem;
  }
  .contactUsCompanyInfoMain p {
    font-size: 1rem !important;
    padding: 0px;
  }
}

@media (min-width: 2000px) {
  .contactUsTopHeadingDiv p {
    font-size: 4rem;
  }
  .contactUs-subHeading p {
    font-size: 2rem;
  }
  #standard-required {
    font-size: 2rem;
  }
  .type-chips {
    font-size: 1.2rem !important;
    padding: 20px 10px !important;
  }
  #standard-multiline-flexible {
    font-size: 1.5rem !important;
    padding-top: 10px;
  }
  .contactUsCompanyInfoMain {
    font-size: 1.5rem;
  }
  .contactUsBtnSubmit {
    font-size: 1.5rem !important;
  }
}
