#main2 {
  padding: 60px 0px;
  background: white;
  overflow: hidden;
}
.aboutOverlayHeading {
  text-align: center;
  /* margin-top: -125px; */
  /* margin-bottom: -80px; */
}
.aboutOverlayHeading p {
  margin: 0px;
  font-size: 2.6rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #f1f1f1 !important;
}

.aboutTopHeadingMain {
  padding: 0px 50px;
}
.aboutTopSubHeading {
  margin: -20px 0px 20px 0px;
}
.aboutTopHeading p {
  font-family: "Raleway", sans-serif !important;
  font-size: 2.5rem;
  text-transform: capitalize;
  color: #03178c;
}
.aboutTopSubHeading p {
  font-family: "Raleway", sans-serif !important;
  margin: 0px !important;
  text-transform: capitalize;
  color: #03178c;
  text-align: justify;
}
.aboutUsHeadingBorder {
  width: 150px;
  margin: -20px 0px 30px 0px;
  height: 5px;
  background: #4393d9;
  border-radius: 20px;
}
.aboutUsBannerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.aboutUsBannerImg {
  width: 350px;
}

@media (max-width: 768px) {
  .aboutName {
    font-size: 18px;
  }
  .aboutMainContainer {
    padding: 50px;
  }
  .aboutTopHeadingMain {
    padding: 0px 30px 0px 30px;
  }
  .aboutTopHeading p {
    font-size: 2rem;
  }
  .aboutUsBannerImg {
    /* padding-top: 40px; */
    /* width: 400px; */
  }
}
@media (max-width: 425px) {
  .aboutMainContainer {
    padding: 10px;
  }
  /* .aboutOverlayHeading {
    margin-top: -81px;
  }
  .aboutOverlayHeading p {
    font-size: 3em;
  } */
  .aboutTopHeadingMain {
    padding: 0px 30px 0px 30px;
  }
  .aboutTopHeading p {
    font-size: 1.6rem;
  }
  .aboutUsBannerImg {
    width: 80%;
    height: 90%;
  }
}
@media (max-width: 375px) {
  .aboutMainContainer {
    padding: 10px;
  }
  .aboutTopHeadingMain {
    padding: 0px 30px 0px 30px;
  }
  .aboutTopHeading p {
    font-size: 1.6rem;
  }
}
@media (max-width: 320px) {
  .aboutMainContainer {
    padding: 10px;
  }
  .aboutTopHeadingMain {
    padding: 0px 30px 0px 30px;
  }
  .aboutTopHeading p {
    font-size: 1.6rem;
  }
}

/* //min- width media queries */
@media (min-width: 320px) {
  .aboutOverlayHeading p {
    font-size: 2.6rem !important;
  }
}

@media (min-width: 375px) {
  .aboutOverlayHeading p {
    font-size: 3rem !important;
  }
}

@media (min-width: 425px) {
  .aboutOverlayHeading p {
    font-size: 3rem !important;
  }
}
@media (min-width: 448px) {
  .aboutOverlayHeading p {
    font-size: 3.5rem !important;
  }
}
@media (min-width: 518px) {
  .aboutOverlayHeading p {
    font-size: 4rem !important;
  }
  .serviceTopHeading p {
    font-size: 1.7rem;
  }
}

@media (min-width: 768px) {
  .aboutOverlayHeading p {
    font-size: 6rem !important;
  }
}

@media (min-width: 1024px) {
  .aboutUsBannerImg {
    /* padding-top: 50px; */
    width: 80%;
  }
  .aboutOverlayHeading p {
    font-size: 9rem !important;
  }
  /* .serviceTopHeading {
    margin-top: -65px;
  } */
}

@media (min-width: 2000px) {
  .aboutUsBannerImg {
    /* padding-top: 50px; */
    width: 80%;
  }
  .aboutOverlayHeading p {
    font-size: 15rem !important;
  }
  .aboutTopHeading p {
    font-size: 4rem !important;
  }
  .aboutTopSubHeading p {
    font-size: 2rem !important;
  }
}
