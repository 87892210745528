.globalOverlayHeading {
  text-align: center;
  /* margin-top: -125px; */
  /* margin-bottom: -80px; */
}
.globalOverlayHeading p {
  margin: 0px;
  font-size: 2.6rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #f7f7f7 !important;
}
@media (min-width: 320px) {
  .globalOverlayHeading {
    margin-bottom: 0px;
  }
  .globalOverlayHeading p {
    font-size: 2.6rem !important;
  }
}
@media (min-width: 375px) {
  .globalOverlayHeading p {
    font-size: 3rem !important;
  }
}
@media (min-width: 425px) {
  .globalOverlayHeading p {
    font-size: 3rem !important;
  }
}
@media (min-width: 448px) {
  .globalOverlayHeading p {
    font-size: 3.5rem !important;
  }
}
@media (min-width: 518px) {
  .globalOverlayHeading p {
    font-size: 4rem !important;
  }
}

@media (min-width: 768px) {
  .globalOverlayHeading p {
    font-size: 6rem !important;
  }
}

@media (min-width: 1024px) {
  .globalOverlayHeading p {
    font-size: 9rem !important;
  }
}
