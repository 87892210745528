@import url(https://fonts.googleapis.com/css?family=Marcellus+SC&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* width */
  max-width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(194, 194, 194);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(153, 153, 153);
}

@font-face {
  font-family: Nunito;
  src: url(/static/media/Nunito-Regular.d2e691bc.ttf);
}

@font-face {
  font-family: Nunito-Black;
  src: url(/static/media/Nunito-Black.6abf597a.ttf);
}

@font-face {
  font-family: Nunito-BlackItalic;
  src: url(/static/media/Nunito-BlackItalic.d9a81771.ttf);
}

@font-face {
  font-family: Nunito-BoldItalic;
  src: url(/static/media/Nunito-BoldItalic.534bd4bc.ttf);
}

@font-face {
  font-family: Nunito-ExtraBold;
  src: url(/static/media/Nunito-ExtraBold.19caf1c0.ttf);
}

@font-face {
  font-family: Nunito-ExtraBoldItalic;
  src: url(/static/media/Nunito-ExtraBoldItalic.0c327829.ttf);
}

@font-face {
  font-family: Nunito-ExtraLight;
  src: url(/static/media/Nunito-ExtraLight.4f3d2e01.ttf);
}

@font-face {
  font-family: Nunito-ExtraLightItalic;
  src: url(/static/media/Nunito-ExtraLightItalic.c878bb8e.ttf);
}

@font-face {
  font-family: Nunito-Italic;
  src: url(/static/media/Nunito-Italic.80150d05.ttf);
}

@font-face {
  font-family: Nunito-LightItalic;
  src: url(/static/media/Nunito-LightItalic.25df6384.ttf);
}

@font-face {
  font-family: Nunito-SemiBold;
  src: url(/static/media/Nunito-SemiBold.76ae20d4.ttf);
}

@font-face {
  font-family: Nunito-SemiBoldItalic;
  src: url(/static/media/Nunito-SemiBoldItalic.cc0b18c6.ttf);
}

@font-face {
  font-family: Nunito-Bold;
  src: url(/static/media/Nunito-Bold.1cd294a7.ttf);
}

@font-face {
  font-family: Nunito-Italic;
  src: url(/static/media/Nunito-Italic.80150d05.ttf);
}

#navbar {
  transition: ease 0.3s !important;
}

html {
  scroll-behavior: smooth;
}

/* ::-webkit-scrollbar {
  width: 10px;
} */

/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 68, 255, 0.4);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 16, 247, 0.4);
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: linear-gradient(0deg, #5271fd 0%, #2b32b2 100%);
} */

/* 

  .App {
    font-family: "Nunito-Italic";
  }

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
} */

.hamburgerIcon{
    display: none !important;
}

@media(max-width:768px){
    .menuBtn{
        display: none !important;
    }
    .hamburgerIcon{
        display: block !important;
    }
}
#main1 {
  background-color: white;
}
.introImgDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.introImg {
  width: 90%;
  height: 90%;
}
.typingDiv {
  padding: 5% 0% 10% 0%;
  background-color: rgba(255, 255, 255, 0.727);
  position: relative;
  z-index: 9;
}

@media (max-width: 1024px) {
  .introImg {
    width: 100%;
    height: 80%;
  }
}

@media (max-width: 768px) {
  .introImgDiv {
    height: 100%;
  }
  .introImg {
    width: 80%;
    height: 90%;
  }
  .typingDiv {
    margin-top: -10%;
    /* background: green; */
  }
}
@media (max-width: 425px) {
  .introImgDiv {
    height: 100%;
  }
  .typingDiv {
    margin-top: -10%;
    /* background: green; */
  }
}
@media (max-width: 320px) {
  .introImgDiv {
    height: 100%;
  }
  .typingDiv {
    margin-top: -10%;
    /* background: green; */
  }
}

/* @import url("https://fonts.googleapis.com/css?family=Raleway:200,100,400"); */

.mainTypeBody {
  font-family: "Raleway", sans-serif;
  /* height: 100vh; */
  /* background: #333 url("https://image.ibb.co/n5A2HU/showcase.jpg") no-repeat
    center center / cover; */
  /* color: #4393d9; */
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 3rem;
  text-align: center;
  align-items: center;
}

.brix_heading,
h2 {
  font-weight: 200;
  margin: 0.4rem;
}

.brix_heading {
  font-size: 3.5rem;
  text-transform: capitalize;
  font-family: "Marcellus SC", serif;
}

.brix_heading span {
  font-family: "Raleway", sans-serif;
}

h2 {
  font-size: 2rem;
  color: black;
}

/* Cursor */
.txt-type > .txt {
  border-right: 0.2rem solid #03178c;
  font-weight: bold;
}

.typeHeading {
  font-weight: bold;
  color: #03178c;
  font-size: 3em;
}
.typeHeadingBorder {
  margin-top: -20px;
  /* width: 140px; */
  margin-bottom: 20px;
  height: 5px;
  background: #4393d9;
  border-radius: 20px;
}
.topContactDiv {
  transition: 0.3s;
  cursor: pointer;
  width: 70%;
  text-align: center;
  background: #4393d9;
  border-radius: 30px;
  font-size: 20px;
  color: white;
  border: 3px solid #4393d9;
  padding: 10px;
  margin-top: 10%;
  text-transform: capitalize;
}
.topContactDiv:hover {
  background: white;
  color: #4393d9;
}

@media (max-height: 812px) {
  .brix_heading {
    font-size: 2.7rem;
  }
}

@media (min-width: 1200px) {
  .brix_heading {
    font-size: 4rem;
  }
  .typeHeading {
    font-size: 2rem;
  }
}

@media (max-width: 1024px) {
  .typeHeading {
    font-size: 1.5rem;
  }

  .topContactText {
    font-size: 14px;
  }
  .topContactDiv {
    cursor: pointer;
  }
}

@media (max-width: 800px) {
  .container {
    padding: 0 1rem;
  }
  .typeHeading {
    font-size: 2rem;
  }
  .brix_heading {
    font-size: 3rem;
  }
  .topContactText {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .typeHeading {
    font-size: 2rem;
  }
  .brix_heading {
    font-size: 3rem;
  }
  .topContactDiv {
    width: 60%;
    cursor: default;
  }
  .topContactText {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .typeHeading {
    font-size: 1.5rem;
  }
  .brix_heading {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 425px) {
  h2 {
    font-size: 1.5rem;
  }
  .topContactText {
    font-size: 15px;
  }
}

@media (max-width: 375px) {
  .typeHeading {
    font-size: 1.5rem;
  }
  .brix_heading {
    font-size: 2rem;
  }

  .topContactText {
    font-size: 13px;
  }
}

@media (max-width: 320px) {
  .brix_heading {
    font-size: 2rem;
  }
  .topContactText {
    font-size: 13px;
  }

  .topContactDiv {
    width: 80%;
  }
}

@media (min-width: 2000px) {
  .typeHeading {
    font-size: 4rem;
  }
  .brix_heading {
    font-size: 5rem;
  }
  .topContactText {
    font-size: 2rem;
  }
}

.particles {
	position: absolute;
	z-index: 1;
	height: 60vh;
	width: 99vw;
}

@media (max-width: 425px) {
	.particles {
		height: 40vh; 
	}
}

.main4 {
  /* background: #4393d910; */
  padding-bottom: 60px;
}

.platformHeading {
  font-family: "Raleway", sans-serif !important;
  font-size: 3em !important;
  color: #03178c;
  text-transform: capitalize;
}
.platformHeadingBorder {
  width: 250px;
  margin-bottom: 20px;
  height: 5px;
  background: #4393d9;
  border-radius: 20px;
}

.platformTopDiv {
  padding: 50px 30px;
}

.platformType {
  text-align: center;
  color: #03178c;
  /* padding: 50px 0px; */
}
.platformName {
  text-transform: capitalize;
}
.platformTypeDiv {
  /* background: #bde0ff; */
  /* cursor: pointer; */
}

.platformType:hover {
  /* background: #4393d9; */
  color: white;
}
.platformCard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flip-card {
  background-color: transparent;
  /* width: 222px; */
  height: 200px;
  /* border: 1px solid #f1f1f1; */
  -webkit-perspective: 1000px;
          perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}
.platformDesc {
  padding: 4px;
  text-align: left;
}
/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: whitesmoke;
  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: #4393d9;
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  text-transform: capitalize;
}

@media (max-width: 425px) {
  .platformTopDiv {
    padding: 50px 30px;
  }
  .platformHeading {
    font-size: 1.8em !important;
  }
  .platformHeadingBorder {
    width: 150px;
  }
}

@media (max-width: 375px) {
  .platformTopDiv {
    padding: 50px 30px;
  }
  .platformHeading {
    font-size: 1.5em !important;
  }
  .platformHeadingBorder {
    width: 130px;
  }
}
@media (max-width: 320px) {
  .platformHeading {
    font-size: 1.8rem !important;
  }
  .platformHeadingBorder {
    width: 100px;
  }
  .flip-card-back {
    font-size: 13px;
  }
  .flip-card-back > h1 {
    font-size: 1.8em;
  }
}

@media (min-width: 2000px) {
  .platformHeading {
    font-size: 4rem !important;
  }
  .platformHeadingBorder {
    width: 200px;
  }
  .platformName {
    font-size: 2rem !important;
  }
  .flip-card-back {
    font-size: 13px;
  }
  .flip-card-back > h1 {
    font-size: 3em;
  }
  .platformDesc {
    font-size: 1.2rem;
  }
}

#main5 {
  padding: 60px 0px 120px 0px;
  /* background: blue; */
}

.qualityHeading {
  font-family: "Raleway", sans-serif !important;
  font-size: 3em !important;
  text-align: center;
  color: #03178c;
  text-transform: capitalize;
}

.qualityTopDiv {
  /* background: green; */
  padding: 50px 50px;
  align-items: center;
}

.qualityTypeLogo {
  width: 50px;
  height: 50px;
}

.qualityType {
  display: flex;
  flex: 1 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  margin: 0px -50px !important;
  text-transform: capitalize !important;
}

.qualityCardDiv {
  width: 65%;
  padding: 40px 0px;
  cursor: pointer;
}

.shadowDisable {
  box-shadow: none !important;
}
.qualityTypeDesc {
  /* text-align: center; */
}
.qualityTypeDesc {
  transition: 1s;
}
.qualityDescDiv {
  transition: 1s;
  padding: 5% 10%;
  text-align: justify;
  height: 100%;
}

@media (min-width: 320px) {
  .shadowActive {
    box-shadow: 0 1.5rem 1.2rem rgba(0, 0, 0, 0.15) !important;
  }
  .qualityTopDiv {
    padding: 20px 0px;
  }
  .qualityType {
    margin: -10px -5px !important;
  }
  .qualityCardDiv {
    width: 80%;
    padding: 10px 5px;
  }
  .qualityHeading {
    text-align: center;
    font-size: 1.4rem !important;
  }
  .qualityTypeName {
    font-size: 0.8rem !important;
  }
  .qualityTypeDesc {
    font-size: 0.8rem !important;
  }
}
@media (min-width: 375px) {
  .qualityHeading {
    text-align: center;
    font-size: 1.7rem !important;
  }
}

@media (min-width: 768px) {
  .shadowActive {
    box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.15) !important;
  }
  .qualityTopDiv {
    padding: 20px 0px;
  }
  .qualityCardDiv {
    width: 70% !important;
    padding: 30px 10px !important;
  }
  .qualityHeading {
    text-align: center;
    font-size: 2.5rem !important;
  }
  .qualityTypeDesc {
    font-size: 1rem !important;
  }
}

@media (min-width: 1024px) {
  .qualityType {
    margin: 0px -5px !important;
  }
  .qualityTypeName {
    font-size: 1rem !important;
  }
  .qualityType {
    margin: 10px -25px !important;
  }
  .qualityTypeDesc {
    font-size: 1.2rem !important;
  }
}

@media (min-width: 2000px) {
  .qualityHeading {
    font-size: 4rem !important;
  }
  .qualityTypeLogo {
    width: 100px;
    height: 100px;
  }
  .qualityTypeName {
    font-size: 1.5rem !important;
  }
  .qualityTypeDesc {
    font-size: 2rem !important;
  }
}

.globalOverlayHeading {
  text-align: center;
  /* margin-top: -125px; */
  /* margin-bottom: -80px; */
}
.globalOverlayHeading p {
  margin: 0px;
  font-size: 2.6rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #f7f7f7 !important;
}
@media (min-width: 320px) {
  .globalOverlayHeading {
    margin-bottom: 0px;
  }
  .globalOverlayHeading p {
    font-size: 2.6rem !important;
  }
}
@media (min-width: 375px) {
  .globalOverlayHeading p {
    font-size: 3rem !important;
  }
}
@media (min-width: 425px) {
  .globalOverlayHeading p {
    font-size: 3rem !important;
  }
}
@media (min-width: 448px) {
  .globalOverlayHeading p {
    font-size: 3.5rem !important;
  }
}
@media (min-width: 518px) {
  .globalOverlayHeading p {
    font-size: 4rem !important;
  }
}

@media (min-width: 768px) {
  .globalOverlayHeading p {
    font-size: 6rem !important;
  }
}

@media (min-width: 1024px) {
  .globalOverlayHeading p {
    font-size: 9rem !important;
  }
}

#main3 {
  padding: 60px 0px;
  background: #f1f1f1;
  overflow: hidden;
}

.serviceOverlayHeading p {
  color: white !important;
}

.serviceTopHeadingMain {
  text-align: center;
}
.serviceTopSubHeading {
  margin: -20px 0px 20px 0px;
}
.serviceTopHeading p {
  font-family: "Raleway", sans-serif !important;
  font-size: 1.5rem;
  text-transform: capitalize;
  color: #03178c;
}

.serviceTopSubHeading p {
  font-family: "Raleway", sans-serif !important;
  margin: 0px !important;
  text-transform: capitalize;
  color: #03178c;
}
#serviceRootCard {
  box-shadow: none;
}
.servicesCard {
  position: relative;
  top: -5px;
  transition: 0.5s;
  margin: 20px;
  box-shadow: 8px 8px 65px 0 rgba(0, 0, 0, 0.1);
}

.serviceName {
  font-weight: bold;
}
.serviceButton {
  transition: 0.5s;
  border: 2px solid #4393d9;
  padding: 10px 35px;
  border-radius: 30px;
  font-size: 0.9rem;
  text-transform: uppercase;
  color: #4393d9;
}
.serviceButton:hover {
  background: #4393d9;
  color: white;
}
.serviceLogo {
  width: 120px;
  height: 120px;
}
.serviceSubHeading {
  color: gray;
  text-transform: capitalize;
}
.serviceDesc {
  text-align: left;
}

.serviceDesc::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.serviceDesc::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.serviceDesc::-webkit-scrollbar-thumb {
  background: #91c8f8;
  border-radius: 100px;
}

/* Handle on hover */
.serviceDesc::-webkit-scrollbar-thumb:hover {
  background: #91c8f8;
}

@media (min-width: 1024px) {
  .originalServiceCard:hover {
    top: 0px;
    box-shadow: none;
  }
  .cardCenterMove {
    position: relative;
    top: 40px !important;
  }
  .cardCenterMove:hover {
    box-shadow: none;
    top: 50px !important;
  }
  .serviceButton {
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .serviceMainContainer {
    padding: 10px;
  }
}

@media (max-width: 375px) {
  .serviceMainContainer {
    padding: 10px;
  }
  .serviceTopHeading p {
    font-size: 1.7rem;
  }
}

@media (max-width: 320px) {
  .serviceMainContainer {
    padding: 5px;
  }
  .servicesCard {
    margin: 5px 5px 10px 5px;
  }
  .MuiCardContent-root {
    padding: 3px !important;
  }
  #serviceRootCard {
    min-width: 100% !important;
  }
  .serviceLogo {
    width: 40%;
    height: 30%;
  }
  .serviceTopHeading p {
    font-size: 1.7rem;
  }
  .serviceName {
    font-size: 0.9rem;
  }
  .serviceDesc {
    font-size: 0.8rem;
  }
  .serviceButton {
    font-size: 0.6rem;
  }
}

/* //min- width media queries */
@media (min-width: 320px) {
  .servicesCard {
    box-shadow: 8px 8px 35px 0 rgba(0, 0, 0, 0.1);
    margin: 5px 5px 10px 5px;
  }
  .serviceTopHeadingMain {
    padding: 10px 30px 0px 30px;
    margin-top: -30px;
  }
  .MuiCardContent-root {
    padding: 3px !important;
  }
  #serviceRootCard {
    min-width: 100% !important;
  }
  .serviceLogo {
    width: 40%;
    height: 30%;
  }
  .serviceButton {
    font-size: 0.6rem;
  }

  .cardCenterMove {
    top: -10px;
  }
  .cardCenterMove:hover {
    top: 0px;
    box-shadow: none;
  }
  .originalServiceCard {
    top: -10px;
  }
  .originalServiceCard:hover {
    top: 0px;
    box-shadow: none;
  }
  .serviceDesc {
    max-height: 133px;
    /* background: green; */
    overflow: scroll;
  }
  .cardContentDiv {
    max-height: 295px;
  }
  .serviceTopHeading p {
    font-size: 1.7rem;
  }
}
@media (min-width: 352px) {
  .cardContentDiv {
    min-height: 310px;
  }
}

@media (min-width: 375px) {
  .serviceDesc {
    font-size: 0.9rem;
  }
  .serviceButton {
    font-size: 0.8rem;
  }
}

@media (min-width: 425px) {
  .serviceTopHeading p {
    font-size: 1.7rem;
  }
  .serviceDesc {
    font-size: 0.8rem !important;
  }
  .serviceDesc {
    max-height: 120px;
  }
}
@media (min-width: 448px) {
  .serviceMainContainer {
    padding: 10px;
  }
  .serviceTopHeading p {
    font-size: 1.7rem;
  }
  .serviceName {
    font-size: 1.2rem !important;
  }
  .serviceDesc {
    max-height: 100px !important;
  }
}
@media (min-width: 518px) {
  .serviceMainContainer {
    padding: 10px;
  }
  .MuiCardContent-root {
    padding: 10px !important;
  }
  .serviceTopHeading p {
    font-size: 1.7rem;
  }
  .serviceName {
    font-size: 1.3rem !important;
  }
  .serviceDesc {
    font-size: 1rem;
  }
  .serviceDesc {
    max-height: 100px;
  }
}

@media (min-width: 566px) {
  .serviceDesc {
    max-height: 90px !important;
  }
}

@media (min-width: 600px) {
  .servicesCard {
    margin: 15px;
  }
  .serviceName {
    font-size: 1.2rem !important;
  }
  .serviceDesc {
    font-size: 0.9rem !important;
  }
}

@media (min-width: 640px) {
  .serviceName {
    font-size: 1rem !important;
  }
  .serviceDesc {
    max-height: 110px !important;
  }
}

@media (min-width: 768px) {
  .servicesCard {
    margin: 20px;
  }
  .serviceTopHeading p {
    font-size: 2rem !important;
  }
  .serviceName {
    font-size: 1.2rem !important;
  }
  .cardContentDiv {
    min-height: 400px;
  }
  .serviceDesc {
    max-height: 100% !important;
  }
}

@media (min-width: 960px) {
  .cardContentDiv {
    min-height: 430px;
  }
  .serviceDesc {
    max-height: auto !important;
  }
}

@media (min-width: 1024px) {
  .serviceLogo {
    width: 30%;
    height: 20%;
  }
  .servicesCard {
    margin: 20px;
  }
  .serviceTopHeadingMain {
    margin-top: 0px;
  }
  .serviceTopHeading {
    margin-top: -65px;
  }
  .serviceTopHeading p {
    font-size: 3rem !important;
  }
  .serviceName {
    font-size: 1.2rem !important;
  }
  .cardContentDiv {
    min-height: none !important;
    max-height: none !important;
  }
  .serviceDesc {
    overflow: hidden !important;
    font-size: 1.1rem !important;
  }
}

@media (min-width: 2000px) {
  .serviceOverlayHeading p {
    font-size: 15rem !important;
  }
  .serviceTopHeading p {
    font-size: 4rem !important;
  }
  .serviceTopSubHeading p {
    font-size: 2rem !important;
  }
  .serviceName {
    font-size: 2rem !important;
  }
  .serviceSubHeading {
    font-size: 1.8rem !important;
  }
  .serviceDesc {
    overflow: hidden !important;
    font-size: 1.8rem !important;
  }
  .serviceButton {
    font-size: 1.5rem !important;
  }
}

#main2 {
  padding: 60px 0px;
  background: white;
  overflow: hidden;
}
.aboutOverlayHeading {
  text-align: center;
  /* margin-top: -125px; */
  /* margin-bottom: -80px; */
}
.aboutOverlayHeading p {
  margin: 0px;
  font-size: 2.6rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #f1f1f1 !important;
}

.aboutTopHeadingMain {
  padding: 0px 50px;
}
.aboutTopSubHeading {
  margin: -20px 0px 20px 0px;
}
.aboutTopHeading p {
  font-family: "Raleway", sans-serif !important;
  font-size: 2.5rem;
  text-transform: capitalize;
  color: #03178c;
}
.aboutTopSubHeading p {
  font-family: "Raleway", sans-serif !important;
  margin: 0px !important;
  text-transform: capitalize;
  color: #03178c;
  text-align: justify;
}
.aboutUsHeadingBorder {
  width: 150px;
  margin: -20px 0px 30px 0px;
  height: 5px;
  background: #4393d9;
  border-radius: 20px;
}
.aboutUsBannerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.aboutUsBannerImg {
  width: 350px;
}

@media (max-width: 768px) {
  .aboutName {
    font-size: 18px;
  }
  .aboutMainContainer {
    padding: 50px;
  }
  .aboutTopHeadingMain {
    padding: 0px 30px 0px 30px;
  }
  .aboutTopHeading p {
    font-size: 2rem;
  }
  .aboutUsBannerImg {
    /* padding-top: 40px; */
    /* width: 400px; */
  }
}
@media (max-width: 425px) {
  .aboutMainContainer {
    padding: 10px;
  }
  /* .aboutOverlayHeading {
    margin-top: -81px;
  }
  .aboutOverlayHeading p {
    font-size: 3em;
  } */
  .aboutTopHeadingMain {
    padding: 0px 30px 0px 30px;
  }
  .aboutTopHeading p {
    font-size: 1.6rem;
  }
  .aboutUsBannerImg {
    width: 80%;
    height: 90%;
  }
}
@media (max-width: 375px) {
  .aboutMainContainer {
    padding: 10px;
  }
  .aboutTopHeadingMain {
    padding: 0px 30px 0px 30px;
  }
  .aboutTopHeading p {
    font-size: 1.6rem;
  }
}
@media (max-width: 320px) {
  .aboutMainContainer {
    padding: 10px;
  }
  .aboutTopHeadingMain {
    padding: 0px 30px 0px 30px;
  }
  .aboutTopHeading p {
    font-size: 1.6rem;
  }
}

/* //min- width media queries */
@media (min-width: 320px) {
  .aboutOverlayHeading p {
    font-size: 2.6rem !important;
  }
}

@media (min-width: 375px) {
  .aboutOverlayHeading p {
    font-size: 3rem !important;
  }
}

@media (min-width: 425px) {
  .aboutOverlayHeading p {
    font-size: 3rem !important;
  }
}
@media (min-width: 448px) {
  .aboutOverlayHeading p {
    font-size: 3.5rem !important;
  }
}
@media (min-width: 518px) {
  .aboutOverlayHeading p {
    font-size: 4rem !important;
  }
  .serviceTopHeading p {
    font-size: 1.7rem;
  }
}

@media (min-width: 768px) {
  .aboutOverlayHeading p {
    font-size: 6rem !important;
  }
}

@media (min-width: 1024px) {
  .aboutUsBannerImg {
    /* padding-top: 50px; */
    width: 80%;
  }
  .aboutOverlayHeading p {
    font-size: 9rem !important;
  }
  /* .serviceTopHeading {
    margin-top: -65px;
  } */
}

@media (min-width: 2000px) {
  .aboutUsBannerImg {
    /* padding-top: 50px; */
    width: 80%;
  }
  .aboutOverlayHeading p {
    font-size: 15rem !important;
  }
  .aboutTopHeading p {
    font-size: 4rem !important;
  }
  .aboutTopSubHeading p {
    font-size: 2rem !important;
  }
}

#main6 {
  padding-bottom: 100px;
}
.locationOverlayHeading p {
  margin-bottom: 30px;
  color: #f1f1f1 !important;
}

@media (min-width: 2000px) {
  .locationOverlayHeading p {
    font-size: 13rem !important;
  }
}

#main8 {
  position: relative;
  background: #5271ff;
  width: 100%;
  /* bottom: -300px; */
  /* z-index: -1; */
}
.contactUsMain {
  position: relative;
  top: -100px;
  background: #fff;
  padding: 70px 50px;
  margin: 50px 50px 0px 50px;
  /* margin-bottom: -200px; */
  text-transform: capitalize;
  box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.055),
    0 -0rem 2rem rgba(0, 0, 0, 0.055);
}
.contactUsTopHeadingDiv p {
  color: #03178c;
  font-size: 3rem;
}

.contactUsChildMain {
  display: flex;
  /* flex-direction: row-reverse; */
}

.contactUsBtnSubmit {
  margin-top: 20px !important;
}

.infoTextDivChild {
  margin-right: 40px !important;
}
#standard-multiline-flexible {
  height: 80px !important;
}

.contactUsMoreInfoDiv {
  padding-right: 30px;
}
.contactUsCompanyInfoDiv {
  text-transform: capitalize;
}

.contactUsCompanyInfoChild h3 {
  color: #03178c;
}

@media (max-width: 768px) {
  .contactUsCompanyInfoMain {
    margin-top: 50px !important ;
    text-align: justify;
  }
}

@media (max-width: 425px) {
  .contactUsMain {
    margin: 20px 20px 0px 20px;
    padding: 10px 30px;
    /* margin-bottom: -200px; */
  }
  .contactUsTopHeadingDiv p {
    font-size: 2rem;
  }
}

@media (max-width: 320px) {
  .contactUsMain {
    /* margin-bottom: -200px; */
  }
  .contactUsTopHeadingDiv p {
    font-size: 1.5rem;
  }
  .contactUsCompanyInfoMain p {
    font-size: 1rem !important;
    padding: 0px;
  }
}

@media (min-width: 2000px) {
  .contactUsTopHeadingDiv p {
    font-size: 4rem;
  }
  .contactUs-subHeading p {
    font-size: 2rem;
  }
  #standard-required {
    font-size: 2rem;
  }
  .type-chips {
    font-size: 1.2rem !important;
    padding: 20px 10px !important;
  }
  #standard-multiline-flexible {
    font-size: 1.5rem !important;
    padding-top: 10px;
  }
  .contactUsCompanyInfoMain {
    font-size: 1.5rem;
  }
  .contactUsBtnSubmit {
    font-size: 1.5rem !important;
  }
}

.footerMainDiv {
  background: #2b32c1;
  padding: 5px 0px;
}
.footerMainDiv p {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.1rem;
  letter-spacing: 5px;
  color: white;
}

@media (min-width: 320px) {
  .footerMainDiv p {
    font-size: 0.8rem;
    letter-spacing: 5px;
    color: white;
  }
}

@media (min-width: 768px) {
  .footerMainDiv p {
    font-size: 1.1rem;
    letter-spacing: 5px;
    color: white;
  }
}

@media (min-width: 2000px) {
  .footerMainDiv p {
    font-size: 2rem;
    letter-spacing: 5px;
  }
}

#testimonialContainer {
	background-color: #f1f1f1;
	margin-bottom: 10em;
}

#itemsContainer {
	padding-top: 5em !important;
	padding-bottom: 5em !important;
}

.item {
	margin: 1em 2em !important;
	padding: 2em !important;
	border-radius: 1em !important;
	border-bottom: 0.5em solid navy !important;
}

.profile {
	margin-top: 1em !important;
}

.clientReview {
	font-family: 'Nunito-Bold' !important;
	font-size: 1.1em !important;
}

.clientName {
	font-family: 'Nunito-Black' !important;
}

.clientPosition {
	font-family: 'Nunito-BoldItalic' !important;
}

/* width */
.horizontalScrollWrapper::-webkit-scrollbar {
	height: 10px;
}

/* Track */
.horizontalScrollWrapper ::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.horizontalScrollWrapper::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
.horizontalScrollWrapper::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.horizontalScrollWrapper {
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
	overflow: auto;
	white-space: nowrap;
}

.horizontalScrollWrapper > div {
	text-align: start;
	width: 450px;
	display: inline-block;
	white-space: normal;
	margin: auto;
}

.coverDiv1 {
  width: 100%;
  height: 100vh;
}

