#main3 {
  padding: 60px 0px;
  background: #f1f1f1;
  overflow: hidden;
}

.serviceOverlayHeading p {
  color: white !important;
}

.serviceTopHeadingMain {
  text-align: center;
}
.serviceTopSubHeading {
  margin: -20px 0px 20px 0px;
}
.serviceTopHeading p {
  font-family: "Raleway", sans-serif !important;
  font-size: 1.5rem;
  text-transform: capitalize;
  color: #03178c;
}

.serviceTopSubHeading p {
  font-family: "Raleway", sans-serif !important;
  margin: 0px !important;
  text-transform: capitalize;
  color: #03178c;
}
#serviceRootCard {
  box-shadow: none;
}
.servicesCard {
  position: relative;
  top: -5px;
  transition: 0.5s;
  margin: 20px;
  box-shadow: 8px 8px 65px 0 rgba(0, 0, 0, 0.1);
}

.serviceName {
  font-weight: bold;
}
.serviceButton {
  transition: 0.5s;
  border: 2px solid #4393d9;
  padding: 10px 35px;
  border-radius: 30px;
  font-size: 0.9rem;
  text-transform: uppercase;
  color: #4393d9;
}
.serviceButton:hover {
  background: #4393d9;
  color: white;
}
.serviceLogo {
  width: 120px;
  height: 120px;
}
.serviceSubHeading {
  color: gray;
  text-transform: capitalize;
}
.serviceDesc {
  text-align: left;
}

.serviceDesc::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.serviceDesc::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.serviceDesc::-webkit-scrollbar-thumb {
  background: #91c8f8;
  border-radius: 100px;
}

/* Handle on hover */
.serviceDesc::-webkit-scrollbar-thumb:hover {
  background: #91c8f8;
}

@media (min-width: 1024px) {
  .originalServiceCard:hover {
    top: 0px;
    box-shadow: none;
  }
  .cardCenterMove {
    position: relative;
    top: 40px !important;
  }
  .cardCenterMove:hover {
    box-shadow: none;
    top: 50px !important;
  }
  .serviceButton {
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .serviceMainContainer {
    padding: 10px;
  }
}

@media (max-width: 375px) {
  .serviceMainContainer {
    padding: 10px;
  }
  .serviceTopHeading p {
    font-size: 1.7rem;
  }
}

@media (max-width: 320px) {
  .serviceMainContainer {
    padding: 5px;
  }
  .servicesCard {
    margin: 5px 5px 10px 5px;
  }
  .MuiCardContent-root {
    padding: 3px !important;
  }
  #serviceRootCard {
    min-width: 100% !important;
  }
  .serviceLogo {
    width: 40%;
    height: 30%;
  }
  .serviceTopHeading p {
    font-size: 1.7rem;
  }
  .serviceName {
    font-size: 0.9rem;
  }
  .serviceDesc {
    font-size: 0.8rem;
  }
  .serviceButton {
    font-size: 0.6rem;
  }
}

/* //min- width media queries */
@media (min-width: 320px) {
  .servicesCard {
    box-shadow: 8px 8px 35px 0 rgba(0, 0, 0, 0.1);
    margin: 5px 5px 10px 5px;
  }
  .serviceTopHeadingMain {
    padding: 10px 30px 0px 30px;
    margin-top: -30px;
  }
  .MuiCardContent-root {
    padding: 3px !important;
  }
  #serviceRootCard {
    min-width: 100% !important;
  }
  .serviceLogo {
    width: 40%;
    height: 30%;
  }
  .serviceButton {
    font-size: 0.6rem;
  }

  .cardCenterMove {
    top: -10px;
  }
  .cardCenterMove:hover {
    top: 0px;
    box-shadow: none;
  }
  .originalServiceCard {
    top: -10px;
  }
  .originalServiceCard:hover {
    top: 0px;
    box-shadow: none;
  }
  .serviceDesc {
    max-height: 133px;
    /* background: green; */
    overflow: scroll;
  }
  .cardContentDiv {
    max-height: 295px;
  }
  .serviceTopHeading p {
    font-size: 1.7rem;
  }
}
@media (min-width: 352px) {
  .cardContentDiv {
    min-height: 310px;
  }
}

@media (min-width: 375px) {
  .serviceDesc {
    font-size: 0.9rem;
  }
  .serviceButton {
    font-size: 0.8rem;
  }
}

@media (min-width: 425px) {
  .serviceTopHeading p {
    font-size: 1.7rem;
  }
  .serviceDesc {
    font-size: 0.8rem !important;
  }
  .serviceDesc {
    max-height: 120px;
  }
}
@media (min-width: 448px) {
  .serviceMainContainer {
    padding: 10px;
  }
  .serviceTopHeading p {
    font-size: 1.7rem;
  }
  .serviceName {
    font-size: 1.2rem !important;
  }
  .serviceDesc {
    max-height: 100px !important;
  }
}
@media (min-width: 518px) {
  .serviceMainContainer {
    padding: 10px;
  }
  .MuiCardContent-root {
    padding: 10px !important;
  }
  .serviceTopHeading p {
    font-size: 1.7rem;
  }
  .serviceName {
    font-size: 1.3rem !important;
  }
  .serviceDesc {
    font-size: 1rem;
  }
  .serviceDesc {
    max-height: 100px;
  }
}

@media (min-width: 566px) {
  .serviceDesc {
    max-height: 90px !important;
  }
}

@media (min-width: 600px) {
  .servicesCard {
    margin: 15px;
  }
  .serviceName {
    font-size: 1.2rem !important;
  }
  .serviceDesc {
    font-size: 0.9rem !important;
  }
}

@media (min-width: 640px) {
  .serviceName {
    font-size: 1rem !important;
  }
  .serviceDesc {
    max-height: 110px !important;
  }
}

@media (min-width: 768px) {
  .servicesCard {
    margin: 20px;
  }
  .serviceTopHeading p {
    font-size: 2rem !important;
  }
  .serviceName {
    font-size: 1.2rem !important;
  }
  .cardContentDiv {
    min-height: 400px;
  }
  .serviceDesc {
    max-height: 100% !important;
  }
}

@media (min-width: 960px) {
  .cardContentDiv {
    min-height: 430px;
  }
  .serviceDesc {
    max-height: auto !important;
  }
}

@media (min-width: 1024px) {
  .serviceLogo {
    width: 30%;
    height: 20%;
  }
  .servicesCard {
    margin: 20px;
  }
  .serviceTopHeadingMain {
    margin-top: 0px;
  }
  .serviceTopHeading {
    margin-top: -65px;
  }
  .serviceTopHeading p {
    font-size: 3rem !important;
  }
  .serviceName {
    font-size: 1.2rem !important;
  }
  .cardContentDiv {
    min-height: none !important;
    max-height: none !important;
  }
  .serviceDesc {
    overflow: hidden !important;
    font-size: 1.1rem !important;
  }
}

@media (min-width: 2000px) {
  .serviceOverlayHeading p {
    font-size: 15rem !important;
  }
  .serviceTopHeading p {
    font-size: 4rem !important;
  }
  .serviceTopSubHeading p {
    font-size: 2rem !important;
  }
  .serviceName {
    font-size: 2rem !important;
  }
  .serviceSubHeading {
    font-size: 1.8rem !important;
  }
  .serviceDesc {
    overflow: hidden !important;
    font-size: 1.8rem !important;
  }
  .serviceButton {
    font-size: 1.5rem !important;
  }
}
