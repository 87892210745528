.main4 {
  /* background: #4393d910; */
  padding-bottom: 60px;
}

.platformHeading {
  font-family: "Raleway", sans-serif !important;
  font-size: 3em !important;
  color: #03178c;
  text-transform: capitalize;
}
.platformHeadingBorder {
  width: 250px;
  margin-bottom: 20px;
  height: 5px;
  background: #4393d9;
  border-radius: 20px;
}

.platformTopDiv {
  padding: 50px 30px;
}

.platformType {
  text-align: center;
  color: #03178c;
  /* padding: 50px 0px; */
}
.platformName {
  text-transform: capitalize;
}
.platformTypeDiv {
  /* background: #bde0ff; */
  /* cursor: pointer; */
}

.platformType:hover {
  /* background: #4393d9; */
  color: white;
}
.platformCard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flip-card {
  background-color: transparent;
  /* width: 222px; */
  height: 200px;
  /* border: 1px solid #f1f1f1; */
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
.platformDesc {
  padding: 4px;
  text-align: left;
}
/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: whitesmoke;
  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: #4393d9;
  color: white;
  transform: rotateY(180deg);
  text-transform: capitalize;
}

@media (max-width: 425px) {
  .platformTopDiv {
    padding: 50px 30px;
  }
  .platformHeading {
    font-size: 1.8em !important;
  }
  .platformHeadingBorder {
    width: 150px;
  }
}

@media (max-width: 375px) {
  .platformTopDiv {
    padding: 50px 30px;
  }
  .platformHeading {
    font-size: 1.5em !important;
  }
  .platformHeadingBorder {
    width: 130px;
  }
}
@media (max-width: 320px) {
  .platformHeading {
    font-size: 1.8rem !important;
  }
  .platformHeadingBorder {
    width: 100px;
  }
  .flip-card-back {
    font-size: 13px;
  }
  .flip-card-back > h1 {
    font-size: 1.8em;
  }
}

@media (min-width: 2000px) {
  .platformHeading {
    font-size: 4rem !important;
  }
  .platformHeadingBorder {
    width: 200px;
  }
  .platformName {
    font-size: 2rem !important;
  }
  .flip-card-back {
    font-size: 13px;
  }
  .flip-card-back > h1 {
    font-size: 3em;
  }
  .platformDesc {
    font-size: 1.2rem;
  }
}
