#main1 {
  background-color: white;
}
.introImgDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.introImg {
  width: 90%;
  height: 90%;
}
.typingDiv {
  padding: 5% 0% 10% 0%;
  background-color: rgba(255, 255, 255, 0.727);
  position: relative;
  z-index: 9;
}

@media (max-width: 1024px) {
  .introImg {
    width: 100%;
    height: 80%;
  }
}

@media (max-width: 768px) {
  .introImgDiv {
    height: 100%;
  }
  .introImg {
    width: 80%;
    height: 90%;
  }
  .typingDiv {
    margin-top: -10%;
    /* background: green; */
  }
}
@media (max-width: 425px) {
  .introImgDiv {
    height: 100%;
  }
  .typingDiv {
    margin-top: -10%;
    /* background: green; */
  }
}
@media (max-width: 320px) {
  .introImgDiv {
    height: 100%;
  }
  .typingDiv {
    margin-top: -10%;
    /* background: green; */
  }
}
