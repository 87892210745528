@font-face {
  font-family: Nunito;
  src: url(./assets/fonts/Nunito-Regular.ttf);
}

@font-face {
  font-family: Nunito-Black;
  src: url(./assets/fonts/Nunito-Black.ttf);
}

@font-face {
  font-family: Nunito-BlackItalic;
  src: url(./assets/fonts/Nunito-BlackItalic.ttf);
}

@font-face {
  font-family: Nunito-BoldItalic;
  src: url(./assets/fonts/Nunito-BoldItalic.ttf);
}

@font-face {
  font-family: Nunito-ExtraBold;
  src: url(./assets/fonts/Nunito-ExtraBold.ttf);
}

@font-face {
  font-family: Nunito-ExtraBoldItalic;
  src: url(./assets/fonts/Nunito-ExtraBoldItalic.ttf);
}

@font-face {
  font-family: Nunito-ExtraLight;
  src: url(./assets/fonts/Nunito-ExtraLight.ttf);
}

@font-face {
  font-family: Nunito-ExtraLightItalic;
  src: url(./assets/fonts/Nunito-ExtraLightItalic.ttf);
}

@font-face {
  font-family: Nunito-Italic;
  src: url(./assets/fonts/Nunito-Italic.ttf);
}

@font-face {
  font-family: Nunito-LightItalic;
  src: url(./assets/fonts/Nunito-LightItalic.ttf);
}

@font-face {
  font-family: Nunito-SemiBold;
  src: url(./assets/fonts/Nunito-SemiBold.ttf);
}

@font-face {
  font-family: Nunito-SemiBoldItalic;
  src: url(./assets/fonts/Nunito-SemiBoldItalic.ttf);
}

@font-face {
  font-family: Nunito-Bold;
  src: url(./assets/fonts/Nunito-Bold.ttf);
}

@font-face {
  font-family: Nunito-Italic;
  src: url(./assets/fonts/Nunito-Italic.ttf);
}

#navbar {
  transition: ease 0.3s !important;
}

html {
  scroll-behavior: smooth;
}

/* ::-webkit-scrollbar {
  width: 10px;
} */

/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 68, 255, 0.4);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 16, 247, 0.4);
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: linear-gradient(0deg, #5271fd 0%, #2b32b2 100%);
} */

/* 

  .App {
    font-family: "Nunito-Italic";
  }

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
} */
