.hamburgerIcon{
    display: none !important;
}

@media(max-width:768px){
    .menuBtn{
        display: none !important;
    }
    .hamburgerIcon{
        display: block !important;
    }
}