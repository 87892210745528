#main5 {
  padding: 60px 0px 120px 0px;
  /* background: blue; */
}

.qualityHeading {
  font-family: "Raleway", sans-serif !important;
  font-size: 3em !important;
  text-align: center;
  color: #03178c;
  text-transform: capitalize;
}

.qualityTopDiv {
  /* background: green; */
  padding: 50px 50px;
  align-items: center;
}

.qualityTypeLogo {
  width: 50px;
  height: 50px;
}

.qualityType {
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  margin: 0px -50px !important;
  text-transform: capitalize !important;
}

.qualityCardDiv {
  width: 65%;
  padding: 40px 0px;
  cursor: pointer;
}

.shadowDisable {
  box-shadow: none !important;
}
.qualityTypeDesc {
  /* text-align: center; */
}
.qualityTypeDesc {
  transition: 1s;
}
.qualityDescDiv {
  transition: 1s;
  padding: 5% 10%;
  text-align: justify;
  height: 100%;
}

@media (min-width: 320px) {
  .shadowActive {
    box-shadow: 0 1.5rem 1.2rem rgba(0, 0, 0, 0.15) !important;
  }
  .qualityTopDiv {
    padding: 20px 0px;
  }
  .qualityType {
    margin: -10px -5px !important;
  }
  .qualityCardDiv {
    width: 80%;
    padding: 10px 5px;
  }
  .qualityHeading {
    text-align: center;
    font-size: 1.4rem !important;
  }
  .qualityTypeName {
    font-size: 0.8rem !important;
  }
  .qualityTypeDesc {
    font-size: 0.8rem !important;
  }
}
@media (min-width: 375px) {
  .qualityHeading {
    text-align: center;
    font-size: 1.7rem !important;
  }
}

@media (min-width: 768px) {
  .shadowActive {
    box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.15) !important;
  }
  .qualityTopDiv {
    padding: 20px 0px;
  }
  .qualityCardDiv {
    width: 70% !important;
    padding: 30px 10px !important;
  }
  .qualityHeading {
    text-align: center;
    font-size: 2.5rem !important;
  }
  .qualityTypeDesc {
    font-size: 1rem !important;
  }
}

@media (min-width: 1024px) {
  .qualityType {
    margin: 0px -5px !important;
  }
  .qualityTypeName {
    font-size: 1rem !important;
  }
  .qualityType {
    margin: 10px -25px !important;
  }
  .qualityTypeDesc {
    font-size: 1.2rem !important;
  }
}

@media (min-width: 2000px) {
  .qualityHeading {
    font-size: 4rem !important;
  }
  .qualityTypeLogo {
    width: 100px;
    height: 100px;
  }
  .qualityTypeName {
    font-size: 1.5rem !important;
  }
  .qualityTypeDesc {
    font-size: 2rem !important;
  }
}
