/* @import url("https://fonts.googleapis.com/css?family=Raleway:200,100,400"); */
@import url("https://fonts.googleapis.com/css?family=Marcellus+SC&display=swap");

.mainTypeBody {
  font-family: "Raleway", sans-serif;
  /* height: 100vh; */
  /* background: #333 url("https://image.ibb.co/n5A2HU/showcase.jpg") no-repeat
    center center / cover; */
  /* color: #4393d9; */
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 3rem;
  text-align: center;
  align-items: center;
}

.brix_heading,
h2 {
  font-weight: 200;
  margin: 0.4rem;
}

.brix_heading {
  font-size: 3.5rem;
  text-transform: capitalize;
  font-family: "Marcellus SC", serif;
}

.brix_heading span {
  font-family: "Raleway", sans-serif;
}

h2 {
  font-size: 2rem;
  color: black;
}

/* Cursor */
.txt-type > .txt {
  border-right: 0.2rem solid #03178c;
  font-weight: bold;
}

.typeHeading {
  font-weight: bold;
  color: #03178c;
  font-size: 3em;
}
.typeHeadingBorder {
  margin-top: -20px;
  /* width: 140px; */
  margin-bottom: 20px;
  height: 5px;
  background: #4393d9;
  border-radius: 20px;
}
.topContactDiv {
  transition: 0.3s;
  cursor: pointer;
  width: 70%;
  text-align: center;
  background: #4393d9;
  border-radius: 30px;
  font-size: 20px;
  color: white;
  border: 3px solid #4393d9;
  padding: 10px;
  margin-top: 10%;
  text-transform: capitalize;
}
.topContactDiv:hover {
  background: white;
  color: #4393d9;
}

@media (max-height: 812px) {
  .brix_heading {
    font-size: 2.7rem;
  }
}

@media (min-width: 1200px) {
  .brix_heading {
    font-size: 4rem;
  }
  .typeHeading {
    font-size: 2rem;
  }
}

@media (max-width: 1024px) {
  .typeHeading {
    font-size: 1.5rem;
  }

  .topContactText {
    font-size: 14px;
  }
  .topContactDiv {
    cursor: pointer;
  }
}

@media (max-width: 800px) {
  .container {
    padding: 0 1rem;
  }
  .typeHeading {
    font-size: 2rem;
  }
  .brix_heading {
    font-size: 3rem;
  }
  .topContactText {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .typeHeading {
    font-size: 2rem;
  }
  .brix_heading {
    font-size: 3rem;
  }
  .topContactDiv {
    width: 60%;
    cursor: default;
  }
  .topContactText {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .typeHeading {
    font-size: 1.5rem;
  }
  .brix_heading {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 425px) {
  h2 {
    font-size: 1.5rem;
  }
  .topContactText {
    font-size: 15px;
  }
}

@media (max-width: 375px) {
  .typeHeading {
    font-size: 1.5rem;
  }
  .brix_heading {
    font-size: 2rem;
  }

  .topContactText {
    font-size: 13px;
  }
}

@media (max-width: 320px) {
  .brix_heading {
    font-size: 2rem;
  }
  .topContactText {
    font-size: 13px;
  }

  .topContactDiv {
    width: 80%;
  }
}

@media (min-width: 2000px) {
  .typeHeading {
    font-size: 4rem;
  }
  .brix_heading {
    font-size: 5rem;
  }
  .topContactText {
    font-size: 2rem;
  }
}
