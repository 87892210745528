.footerMainDiv {
  background: #2b32c1;
  padding: 5px 0px;
}
.footerMainDiv p {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.1rem;
  letter-spacing: 5px;
  color: white;
}

@media (min-width: 320px) {
  .footerMainDiv p {
    font-size: 0.8rem;
    letter-spacing: 5px;
    color: white;
  }
}

@media (min-width: 768px) {
  .footerMainDiv p {
    font-size: 1.1rem;
    letter-spacing: 5px;
    color: white;
  }
}

@media (min-width: 2000px) {
  .footerMainDiv p {
    font-size: 2rem;
    letter-spacing: 5px;
  }
}
