#main6 {
  padding-bottom: 100px;
}
.locationOverlayHeading p {
  margin-bottom: 30px;
  color: #f1f1f1 !important;
}

@media (min-width: 2000px) {
  .locationOverlayHeading p {
    font-size: 13rem !important;
  }
}
