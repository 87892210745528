.particles {
	position: absolute;
	z-index: 1;
	height: 60vh;
	width: 99vw;
}

@media (max-width: 425px) {
	.particles {
		height: 40vh; 
	}
}
