#testimonialContainer {
	background-color: #f1f1f1;
	margin-bottom: 10em;
}

#itemsContainer {
	padding-top: 5em !important;
	padding-bottom: 5em !important;
}

.item {
	margin: 1em 2em !important;
	padding: 2em !important;
	border-radius: 1em !important;
	border-bottom: 0.5em solid navy !important;
}

.profile {
	margin-top: 1em !important;
}

.clientReview {
	font-family: 'Nunito-Bold' !important;
	font-size: 1.1em !important;
}

.clientName {
	font-family: 'Nunito-Black' !important;
}

.clientPosition {
	font-family: 'Nunito-BoldItalic' !important;
}

/* width */
.horizontalScrollWrapper::-webkit-scrollbar {
	height: 10px;
}

/* Track */
.horizontalScrollWrapper ::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.horizontalScrollWrapper::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
.horizontalScrollWrapper::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.horizontalScrollWrapper {
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
	overflow: auto;
	white-space: nowrap;
}

.horizontalScrollWrapper > div {
	text-align: start;
	width: 450px;
	display: inline-block;
	white-space: normal;
	margin: auto;
}
